import { deleteCouponApi } from "../../apis/deleteCouponApi"

export const deleteCoupon = (code) => {
    return (dispatch) => {
        deleteCouponApi(code)
        dispatch({
            type : "DELETE_COUPON",
            code
        })
    }
}