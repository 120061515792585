import React from 'react';

export default function CartCell (props) {

    const cartLines = props.data.cart.map(cartLine => <CartLine data={cartLine} key={cartLine.PK}/> )
    return (
        <div>
            {cartLines}
        </div>
    )
}


function CartLine (props) {
    return (
        <div>{props.data.name} x {props.data.qty}</div>
    )
}