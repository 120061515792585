import React, {useState, useEffect} from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addProduct } from '../../store/actions/addProduct';
import { editProduct } from '../../store/actions/editProduct';

function AddProduct(props) {

    const [name, setName] = useState(props.data.name); //
    const [brand, setBrand] = useState(props.data.brand); //
    const [category, setCategory] = useState(props.data.category); //
    const [size, setSize] = useState(props.data.size); //
    const [unit, setUnit] = useState(props.data.unit); //
    const [batchCode, setBatchCode] = useState(props.data.batchCode); 
    const [desc, setDesc] = useState(props.data.desc); //
    const [imageUrl, setImageUrl] = useState(props.data.imageUrl); //
    const [gst, setGst] = useState(props.data.gst)
    const [price, setPrice] = useState(props.data.price); //
    const [discountedPrice, setDiscountedPrice] = useState(props.data.discountedPrice || undefined); //
    const [rate, setRate] = useState(discountedPrice < price ? discountedPrice : price); //
    
    const [quantity, setQuantity] = useState(props.data.quantity || 0); 
    const [isFeatured, setIsFeatured] = useState(props.data.isFeatured || false);

    const categories = ["Flours and Daliya","Cereal and Pulses","Oils",
                        "Spices", "Sweeteners", "Salts", "Tea", "Superfoods"]
    const catOptions = []
    categories.map((item,index) => {
        catOptions.push(<option id="catOption" value={item.toLowerCase().trim()} key={index}>{item}</option>)
    })

    const handleBrandChange = (e) => {
        setBrand(e.target.value);
    }

    const handleUnitChange = (e) =>{
        setUnit(e.target.value)
    }

    const handleCategoryChange = (e) => {
        setCategory(e.target.value)
    }
    
    useEffect(()=>{
        setRate(discountedPrice < price ? (discountedPrice/(1 + (gst/100))).toFixed(2) : (price/(1 + (gst/100))).toFixed(2))
    },[gst,price])

    const handleSubmit = (e) => {
        e.preventDefault();
        const product = { 
            PK: name.toLowerCase().replace(/ /g, '')+"-"+size+"-"+brand.toLowerCase().replace(/ /g, ''),
            name,
            brand,
            category,
            batchCode,
            size:parseFloat(size),
            unit,
            desc,
            imageUrl,
            price:parseFloat(price),
            gst:parseFloat(gst),
            rate:parseFloat(rate),
            discountedPrice:parseFloat(discountedPrice),
            quantity:parseInt(quantity),
            isFeatured
        }
        if(props.editMode){
            console.log("here")
            props.editProduct(product)
            props.handleClose();
        }
        else{
            props.addProduct(product)
            props.handleClose();
        }
    }
    return (
        <div>
            <Modal.Body>
                <Form id="productForm" onSubmit={handleSubmit}>
                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Name</Form.Label>
                        {props.editMode
                            ?
                            <Form.Control className="col-8" value={name} readOnly></Form.Control>
                            :
                            <Form.Control className="col-8" required value={name} onChange={e=>setName(e.target.value)}></Form.Control>
                        }
                        </Form.Group>
                    
                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Brand</Form.Label>
                        {props.editMode
                            ?
                            <Form.Control className="col-8" value={brand} readOnly></Form.Control>
                            :
                            <Form.Control className="col-8" as="select" value={brand} onChange={e=>handleBrandChange(e)} required>
                                <option></option>
                                <option id="discOption" value="tnb">The Nature's Bowl</option>
                            </Form.Control>
                        } 
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Category</Form.Label>
                        <Form.Control className="col-8" as="select" value={category} onChange={e=>handleCategoryChange(e)} required>
                            <option></option>
                            {catOptions}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Size</Form.Label>
                        {props.editMode
                            ?
                            <Form.Control className="col-8" value={size} readOnly></Form.Control>
                            :
                            <Form.Control type="number" className="col-8" value={size} onChange={e=>setSize(e.target.value)} required></Form.Control>
                        }
                        </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Unit</Form.Label>
                        {props.editMode
                            ?
                            <Form.Control className="col-8" value={unit} readOnly></Form.Control>
                            :
                            <Form.Control className="col-8" as="select" value={unit} onChange={e=>handleUnitChange(e)} required>
                                <option></option>
                                <option id="unitOption" value="g">G</option>
                                <option id="unitOption" value="Kg">KG</option>
                                <option id="unitOption" value="mL">ML</option>
                                <option id="unitOption" value="L">L</option>
                            </Form.Control>
                        }
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Batch Code</Form.Label>
                        <Form.Control className="col-8" value={batchCode} onChange={e=>setBatchCode(e.target.value)} required></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Description</Form.Label>
                        <Form.Control as="textarea" className="col-8" value={desc} onChange={e=>setDesc(e.target.value)} required></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Image URL</Form.Label>
                        <Form.Control className="col-8" value={imageUrl} onChange={e=>setImageUrl(e.target.value)} required></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Price</Form.Label>
                        <Form.Control type="number" className="col-8" value={price} onChange={e=>setPrice(e.target.value, setDiscountedPrice(e.target.value))} required></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">GST(%)</Form.Label>
                        <Form.Control type="number" className="col-8" value={gst} onChange={e=>setGst(e.target.value)} required></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Rate</Form.Label>
                        <Form.Control className="col-8" value={rate} onChange={e=>setRate(e.target.value)} readOnly required></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Discounted Price</Form.Label>
                        <Form.Control type="number" className="col-8" value={discountedPrice} onChange={e=>setDiscountedPrice(e.target.value)}></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Quantity</Form.Label>
                        <Form.Control type="number" className="col-8" value={quantity} onChange={e=>setQuantity(e.target.value)} required></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Is Featured?</Form.Label>
                        <Form.Check type="checkbox" className="col" checked={isFeatured} onChange={e=>setIsFeatured(!isFeatured)}></Form.Check>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={props.handleClose}>
                Close
            </Button>
            <Button variant="success" type="submit" form="productForm">
                Save
            </Button>
            </Modal.Footer>            
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addProduct : (product) => dispatch(addProduct(product)),
        editProduct : (product) => dispatch(editProduct(product))
     }
}


export default connect(null,mapDispatchToProps)(AddProduct)