import url from '../urls.json'

export const deleteCouponApi = (code) => {
    fetch(`${url.url}/api/coupons`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json'
        },
        body : JSON.stringify({
            code : code
        })})
        .then(res => {
            if (res.status === 200) return res.json()
            console.log("Failed to delete coupon");
            throw new Error("failed to delete coupon");
        })
        .then(resJson => {
            if (resJson.error){
                window.alert("Error deleting coupons, please try again later.")
                return
            }else{
                return
            }
        })
        .catch(error => {
            window.alert("Error deleting coupons, please try again later.")
            console.log("Error ..", error);
        })
    
}