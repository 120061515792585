import url from '../../urls.json'

export const editProduct = (product) => {
    return(dispatch) => {
    fetch(`${url.url}/api/catalog`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body : JSON.stringify({product})
        })
        .then(res => {
            if (res.status === 200) return res.json()
            console.log("Error response state from backend");
            throw new Error("Failed to edit. Check internet or contact dev team.");
        })
        .then(resJson => {
            if (resJson.error){
                console.log("error while editing in db")
                window.alert("Failed to edit. Check internet or contact dev team.")
            }else{
                dispatch({
                    type : "EDIT_PRODUCT",
                    product
                })
            }
        })
        .catch(error => {
            console.log("Error ..", error);
            throw new Error("Request failed")
        })
    
    }
}