import React from 'react'
import { connect } from 'react-redux'
import { deleteCoupon } from '../../store/actions/deleteCoupon'

function CouponItem(props) {

    const handleDelete = () => {
        props.deleteCoupon(props.item.code)
    }

    return (
        <tr>
            <td>{props.index + 1}</td>
            <td>{props.item.code}</td>
            <td>{props.item.title}</td>
            <td>{props.item.discountType}</td>
            <td>{props.item.maxDisc}</td>
            <td>{props.item.amount}</td>
            <td>{props.item.minCart}</td>
            <td>{props.item.desc}</td>
            <td>{props.item.isPrivate ? "PRIVATE" : "PUBLIC"}</td>
            <td>{props.item.couponLimit}</td>
            <td>{props.item.hasBanner ? "YES" : "NO"}</td>
            <td>{props.item.hasBanner ? <a href={props.item.bannerLink}>Link</a> : "N/A"}</td>
            <td>{props.item.hasBanner ? props.item.bannerIdx : "N/A"}</td>
            <td className="d-flex">
                <button className="btn btn-sm btn-primary rounded mr-2" onClick={() => props.handleEdit(props.item)}>Edit</button>
                <button className="btn btn-sm btn-danger rounded" onClick={handleDelete}>Delete</button>
            </td>
        </tr>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteCoupon : (code) => dispatch(deleteCoupon(code))
    }
}

export default connect(null,mapDispatchToProps)(CouponItem)