import React from 'react';
import './App.css';
import Login from './components/Login';
import { connect } from 'react-redux';
import Home from './components/Home';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS


function App(props) {
  return (
    <div>
      {props.authenticated
        ?
          <Home />
        :
          <Login />
      }
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    authenticated : state.auth.authenticated
  }
}
export default connect(mapStateToProps)(App)
