import React, {useState, useEffect, useMemo} from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import url from '../../urls.json';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import CartCell from './CartCell';

export default function Carts (props) {

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        { headerName : '#', field: 'rowNumber' },
        { field : 'userId' },
        { field : 'name' },
        { field : 'email' },
        { field : 'mobile' },
        { field : 'cart', cellRenderer: CartCell, autoHeight: true },
        { field : 'updatedAt' },
    ])
    
    useEffect(() => {
        fetch(`${url.url}/api/cart`,{
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 200) return res.json()
            console.log("Failed to fetch cart data");
            throw new Error("failed to fetch carts");
        })
        .then(resJson => {
            console.log(resJson)
            const rows = resJson.map((cart, idx) => ({
                rowNumber: idx + 1,
                userId : cart.userId,
                name : cart.name,
                email: cart.email,
                mobile: cart.mobile,
                cart: cart.cart,
                updatedAt: Date(cart.updatedAt).toString()
            }))

            setRowData(rows);
        })
        .catch(error => {
                console.log("Error ..", error);
                window.alert("Error fetching carts. Check internet connection, if that is working contact dev")
              })
    }, [])
 
    const defaultColDef = useMemo(() => {
        return {
          width: 200,
          sortable: true,
          editable: true,
          resizable: true,
          filter: true,
        };
      }, []);

    return (
        <div className="ag-theme-alpine" style={{ height : 600, width : 'auto' }} >
            <h2>Abandoned Carts</h2>
            <AgGridReact
               rowData={rowData}
               columnDefs={columnDefs}
               defaultColDef={defaultColDef}>
           </AgGridReact>
        </div>
    )
}