let initState = []

const authReducer = (coupons = initState, action) => {
    switch(action.type){
        case "ADD_COUPON":
            return [...coupons , action.coupon]
        case "LOAD_COUPONS":
            return action.coupons
        case "EDIT_COUPON":
            return coupons.map((item)=>{
                if(item.PK === action.coupon.code){
                    return action.coupon
                }else{
                    return item
                }
            })
        case "DELETE_COUPON":
            return coupons.filter(item=>item.code !== action.code);
        default:
            return coupons
    }
}

export default authReducer