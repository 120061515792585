import url from '../../urls.json'

export const deleteProduct = (PK) => {
    return (dispatch) => {
        fetch(`${url.url}/api/catalog`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
            body : JSON.stringify({PK})
            })
            .then(res => {
                if (res.status === 200) return res.json()
                console.log("Failed to delete product");
                throw new Error("Failed to delete product");
            })
            .then(resJson => {
                if (resJson.error){
                    window.alert("Failed to delete product, check connection or contact dev team.")
                    return;
                }
                else{
                    dispatch({
                        type : "DELETE_PRODUCT",
                        PK
                    })
                }
            })
            .catch(error => {
                console.log("Error ..", error);
                window.alert("Failed to delete product, check connection or contact dev team.")
            })    
    }
}