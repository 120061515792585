import url from '../../urls.json'

export const editCoupon = (coupon) => {
    return (dispatch) => {
        fetch(`${url.url}/api/coupons`,{
            method : "PUT",
            headers: {
                'Content-Type': 'application/json'
                },
            body : JSON.stringify({coupon,})
            }
        )
        .then(res => {
            dispatch({
                type : "EDIT_COUPON",
                coupon
            })
        })
        .catch(err=> {
            window.alert('Error updating coupon')
        })
    }
}