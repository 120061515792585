import React,{useState} from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addCoupon } from '../../store/actions/addCoupon';
import { editCoupon } from '../../store/actions/editCoupon';

function AddCouponForm(props) {

    const [discountType, setDiscountType] = useState(props.data.discountType);
    const [discTypeInvalid, setDiscTypeInvalid] = useState(props.data.discTypeInvalid || false);
    const [code, setCode] = useState(props.data.code);
    const [title, setTitle] = useState(props.data.title);
    const [desc, setDesc] = useState(props.data.desc);
    const [maxDisc, setMaxDisc] = useState(props.data.maxDisc);
    const [amount, setAmount] = useState(props.data.amount || 0);
    const [isPrivate, setIsPrivate] = useState(props.data.isPrivate || false)
    const [minCart, setMinCart] = useState(props.data.minCart || 0);
    const [couponLimit, setCouponLimit] = useState(props.data.couponLimit || 4);
    const [hasBanner, setHasBanner] = useState(props.data.hasBanner || false);
    const [bannerLink, setBannerLink] = useState(props.data.hasBanner ? props.data.bannerLink : '');
    const [bannerIdx, setBannerIdx] = useState(props.data.hasBanner ? props.data.bannerIdx : '');
    const handleOptionChange = (e) => {
        setDiscountType(e.target.value);
    }   

    const handleSubmit = (e) => {
        e.preventDefault();
        if(discountType === ""){
            setDiscTypeInvalid(true)

            return
        }


        const coupon = {code : code.toUpperCase(),title,discountType,desc,amount,minCart,maxDisc,isPrivate,couponLimit, hasBanner, bannerLink, bannerIdx}


        if(props.mode === 'ADD'){
            props.addCoupon(coupon)
            props.close()
        }
        if(props.mode === 'EDIT'){
            props.editCoupon(coupon) 
            props.close()
        }
        

    }

    const couponLimitOptions = [...Array(29).keys()].map(i => <option key={i+1}>{i+1}</option>)

    const couponIdxOptions = [...Array(props.coupons.length).keys()].map(i=><option key={i+1}>{i+1}</option>)
    return (
        <div>
            <Modal.Body>
                <Form id="couponForm" onSubmit={handleSubmit}>
                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Coupon Code</Form.Label>
                        {
                            props.mode === "EDIT"
                                ?<Form.Control className="col-8" required value={code} readOnly></Form.Control>
                                :<Form.Control className="col-8" required value={code} onChange={e=>setCode(e.target.value)}></Form.Control>
                        }
                        
                        {/* <Form.Control.Feedback type="invalid">Please Enter a coupon code</Form.Control.Feedback> */}
                    </Form.Group>
                    
                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Offer type</Form.Label>
                        <Form.Control className="col-8" required as="select" value={discountType} onChange={e=>handleOptionChange(e)} required isInvalid={discTypeInvalid}>
                            <option>Choose</option>
                            <option id="discOption" value="percentage">Percentage</option>
                            <option id="discOption" value="fixed">Fixed</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Title</Form.Label>
                        <Form.Control className="col-8" value={title} onChange={e=>setTitle(e.target.value)} required></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Description</Form.Label>
                        <Form.Control as="textarea" className="col-8" value={desc} onChange={e=>setDesc(e.target.value)}></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">{discountType === "percentage" ? "Percent Discount" : "Amount"}</Form.Label>
                        <Form.Control className="col-8" value={amount} onChange={e=>setAmount(e.target.value)} required></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Maximum Discount(Rs.)</Form.Label>
                        <Form.Control className="col-8" value={maxDisc} onChange={e=>setMaxDisc(e.target.value)}></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Min Cart Value</Form.Label>
                        <Form.Control className="col-8" value={minCart} onChange={e=>setMinCart(e.target.value)}></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Expiry</Form.Label>
                        <Form.Control className="col-8" readOnly placeholder="Feature to be added.."></Form.Control>
                    </Form.Group>

                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Limit per user</Form.Label>
                        <Form.Control className="col-8" as="select" value={couponLimit} onChange={e=>setCouponLimit(parseInt(e.target.value))}>
                            {couponLimitOptions}
                        </Form.Control>
                    </Form.Group>


                    <Form.Group className="row m-2">
                        <Form.Label className="col-4 p-2">Private</Form.Label>
                        <Form.Check type="checkbox" className="col" checked={isPrivate} onChange={e=>setIsPrivate(!isPrivate)}></Form.Check>
                    </Form.Group>
                    {!hasBanner && <u className="text-primary ml-3" role="button" onClick={()=>setHasBanner(true)}>Want a banner for this coupon on the homepage?</u>}
                    {hasBanner && <div className="ml-3 mb-3"><p className="text-primary mb-0 h5">Banner Settings</p><u className="text-secondary mt-0 h6" role="button" onClick={()=>setHasBanner(false)}>Don't want a banner?</u></div>}
                    {hasBanner &&
                        <Form.Group className="row m-2">
                            <Form.Label className="col-4 p-2">Link for Banner</Form.Label>
                            <Form.Control className="col-8" required value={bannerLink} onChange={e=>setBannerLink(e.target.value)} required></Form.Control>
                        </Form.Group>
                    }
                    {hasBanner && 
                        <Form.Group className="row m-2">
                            <Form.Label className="col-4 p-2">Coupon Order (Ascending)</Form.Label>
                            <Form.Control className="col-8" required as="select" value={bannerIdx} onChange={e=>setBannerIdx(parseInt(e.target.value))}>
                                <option value={undefined}>Select Order</option>
                                {couponIdxOptions}
                            </Form.Control>
                        </Form.Group>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={props.close}>
                Close
            </Button>
            <Button variant="success" type="submit" form="couponForm">
                {props.mode === 'ADD' ? 'ADD' : 'SAVE'}
            </Button>
            </Modal.Footer>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        coupons : state.coupons
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addCoupon : (coupon) => dispatch(addCoupon(coupon)),
        editCoupon : (coupon) => dispatch(editCoupon(coupon))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(AddCouponForm)
