import url from '../urls.json'

export const updateStatus = (order,status) => {

    fetch(`${url.url}/api/orders/status`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body : JSON.stringify({
            order,
            status : status,
        })
    })
    .then(res => {
            if (res.status === 200) return res.json()
            console.log("Failed to update status");
            throw new Error("failed to update status");
    })
    .then(resJson => {
            if (resJson.error){
                window.alert("Failed to update status")
            }else{
                window.location.reload();
            }
    })
    .catch(error => {
            console.log("Error updating order status", error);
            window.alert("Failed to update status")
    })        

}