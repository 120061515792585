import React, {useState, useEffect} from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { connect } from 'react-redux';
import { deleteProduct } from '../../store/actions/deleteProduct';
import { loadProducts } from '../../store/actions/loadProducts';
import AddProduct from './AddProduct';
import url from '../../urls.json'

function Inventory(props) {

    const [show, setShow] = useState(false);
    const [showDialog, setShowDialog] = useState(false); 
    const [selectedItem, setSelectedItem] = useState('');
    const [itemForDelete, setItemForDelete] = useState('');
    const [editmode, setEditMode] = useState(false);
    
    const [searchKey, setSearchKey] = useState("");
    const [catfilter, setCatFilter] = useState("all")
    const categories = ["Flours and Daliya","Cereal and Pulses","Oils","Ghee",
    "Spices", "Sweeteners", "Salts", "Tea", "Superfoods"]
    const catOptions = []
    categories.map((item,index) => {
        catOptions.push(<option id="catOption" value={item.toLowerCase().trim()} key={index}>{item}</option>)
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        fetch(`${url.url}/api/catalog/products`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
            })
            .then(res => {
                if (res.status === 200) return res.json()
                console.log("Failed to fetch products");
                throw new Error("failed to fetch products");
            })
            .then(resJson => {
                if (resJson.error){
                    window.alert("Error fetching products from DB! Please check your connection or contact dev team")
                }else{
                    // console.log("Products", resJson)
                    props.loadProducts(resJson.Items)
                }
            })
            .catch(error => {
                console.log("Error ..", error);
                window.alert("Error fetching products from DB! Please check your connection or contact dev team")
            })
    },[])

    const handleAdd = () => {
        setEditMode(false);
        setSelectedItem('')
        handleShow();
    }
    const handleEdit = (item) => {
        setEditMode(true);
        setSelectedItem(item);
        handleShow();
    }

    const confirmDeletion = (item) => {
        // window.alert("here")
        setItemForDelete(item)
        setShowDialog(true);
    }

    const handleDelete = () => {
        props.deleteProduct(itemForDelete.PK);
        setShowDialog(false);
    }

    const productItems = props.products.map((item,index)=> {
        let {PK} = item
        if(catfilter === "all" || item.category == catfilter){
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td><a href={item.imageUrl}>Link</a></td>
                    <td>{item.name}</td>
                    <td>{item.brand}</td>
                    <td>{item.category}</td>
                    <td>{item.size} {item.unit}</td>
                    <td>{item.quantity}</td>
                    <td>&#8377;{item.price}</td>
                    <td>{item.gst}%</td>
                    <td>&#8377;{item.rate}</td>
                    <td>&#8377;{item.discountedPrice}</td>
                    <td><div className="d-flex"><input type="checkbox" checked={item.isFeatured} readOnly></input></div></td>
                    <td style={{overflow:"scroll"}}><div style={{"width": "250px", "height": "100px"}}>{item.desc}</div></td>
                    <td>{item.batchCode}</td>
                    <td>{item.PK}</td>
                    <td className="d-flex justify-content-between"><button className="btn btn-info mr-2" onClick={() => handleEdit(item)}>Edit</button><button className="btn btn-danger" onClick={()=>confirmDeletion(item)}>Del</button></td>
                </tr>
            )
        }
    })
    return (
        <div className="jumbotron">
            <div className="d-flex justify-content-center m-3">
                <button className="btn btn-success" onClick={handleAdd}>Add New Product</button>
            </div>
            <div className="d-flex justify-content-between">
                {/* <input onChange={e=>setSearchKey(e.target.value)} placeholder="search by name"></input> */}
                <select id="catFilter" value={catfilter} onChange={e=>setCatFilter(e.target.value)}>
                    <option id="catOption" value="all">All Products</option>
                    {catOptions}
                </select>
                </div>
            <Modal show={show} onHide={handleClose} centered backdrop={'static'}>
                <Modal.Header closeButton>
                    <Modal.Title>New Product</Modal.Title>
                </Modal.Header>
                <AddProduct handleClose={handleClose} data={selectedItem} editMode={editmode}/>
            </Modal>

            <Modal show={showDialog} onHide={()=>setShowDialog(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Product</Modal.Title>
                </Modal.Header>
                    <Modal.Body>Are you sure? This will delete the product <strong><i>{itemForDelete.name} {itemForDelete.size}{itemForDelete.unit}</i></strong> and all its data from the database.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setShowDialog(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>

            <Table className="mt-5" bordered hover striped variant="" responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Brand</th>
                        <th>Category</th>
                        <th>Size</th>
                        <th>Quantity</th>
                        <th>MRP</th>
                        <th>GST</th>
                        <th>Rate</th>
                        <th>Discounted Price</th>
                        <th>Featured</th>
                        <th>Description</th>
                        <th>Batch Code</th>
                        <th>Product ID</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {productItems}
                </tbody>
            </Table>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        products : state.products
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadProducts : (products) => dispatch(loadProducts(products)),
        deleteProduct : (PK) => dispatch(deleteProduct(PK))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Inventory)
