import url from '../../urls.json'

export const addProduct = (product) => {
    return (dispatch) => {
        fetch(`${url.url}/api/catalog/`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body : JSON.stringify({product,})
        })
        .then(res => {
            if (res.status === 200) return res.json()
            console.log("Failed to add product");
            throw new Error("failed to add product");
        })
        .then(resJson => {
            if (resJson.error){
                window.alert("Failed. Check internet connection or contact dev team.")
                return;
            }
            if (resJson.mismatch) {
                window.alert("Failed! ",resJson.msg)
                return;
            }
            console.log("Product added to DB")
            dispatch({
                type : "ADD_PRODUCT",
                product : {...product, PK:product.name.toLowerCase().replace(/ /g, '')+"-"+product.size+"-"+product.brand.toLowerCase().replace(/ /g, '')}
            }) 
        })
        .catch(error => {
            console.log("Error ..", error);
            window.alert("Failed. Check internet connection or contact dev team.")
        })
    
    }
}
