
import url from '../../urls.json'

export const addCoupon = (coupon) => {
    return (dispatch) => {
        fetch(`${url.url}/api/coupons`, {
            method :"POST",
            headers : {
                'Content-Type': 'application/json'
            },
            body : JSON.stringify({
                coupon,
            })
        })
        .then(res => {
            if (res.status === 200) return res.json()
            console.log("Failed to add coupon");
            throw new Error("failed to add coupon");
        })
        .then(resJson => {
            if (resJson.error){
                console.log("error while adding coupon to db")
                window.alert("Error! Please check interet your connection.")
                return
            }else{
                console.log("coupon added");
                dispatch({
                    type : "ADD_COUPON",
                    coupon
                })
            }
        })
        .catch(error => {
            console.log("Error ..", error);
            window.alert("Error! Please check interet your connection.")
            return
        })
}
}

// export const addCoupon = (coupon) => {
//     return async function(dispatch) {
//         addCouponApi(coupon);
//         dispatch({
//             type : "ADD_COUPON",
//             coupon
//         })
//     }
// 
