let initState = []

export default function productsReducer(products=initState, action){
    switch(action.type){
        case "ADD_PRODUCT":
            return [...products, action.product]
        case "LOAD_PRODUCTS":
            return action.products
        case "EDIT_PRODUCT":
            return products.map((item)=>{
                if(item.PK === action.product.PK){
                    return action.product
                }else{
                    return item
                }
            })
        case "DELETE_PRODUCT":
            return products.filter(item=>item.PK !== action.PK)
        default:
            return products
    }
}