import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from './reducers/rootReducer'
import thunk from 'redux-thunk';


function saveToLocalStorage(state){
    try{
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    }catch(e) {
        console.log("Error while saving state to localStorage", e)
    }
}

function loadFromLocalStorage(){
    try{
        const serializedStateFromStorage = localStorage.getItem('state');
        if (serializedStateFromStorage === null) return undefined;
        return JSON.parse(serializedStateFromStorage)
    }catch(e){
        console.log("Error while loading state from localStorage", e)
    }
}

const persistedState = loadFromLocalStorage();
console.log("persisted state",persistedState)

// const store = compose(
//     applyMiddleware(thunk),
//     window.devToolsExtension && window.devToolsExtension(),
// )(createStore)(rootReducer);

const store = createStore(
    rootReducer,
    persistedState,
    applyMiddleware(thunk)
)

store.subscribe(() => saveToLocalStorage(store.getState()))

export default store;