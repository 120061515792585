import React, {useState, useEffect} from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { loadCoupons } from '../../store/actions/loadCoupons';
import AddCouponForm from './AddCouponForm';
import CouponItem from './CouponItem';
import url from '../../urls.json'

function Coupons(props) {

    const [show, setShow] = useState(false);
    const [mode, setMode] = useState(undefined);
    const [selectedCoupon, setSelectedCoupon] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAdd = () => {
        setMode('ADD');
        setSelectedCoupon({});
        setShow(true);
    }

    const handleEdit = (cpn) => {
        setMode('EDIT');
        setSelectedCoupon(cpn);
        setShow(true);
    }

    useEffect(()=>{
        fetch(`${url.url}/api/coupons`,{
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 200) return res.json()
            console.log("Failed to fetch coupons");
            throw new Error("failed to fetch coupons");
        })
        .then(resJson => {
            if (resJson.error){
                // console.log("coupons----",resJson)
                window.alert("Error fetching coupons. Check internet connection, if that is working contact dev team.")
            }else{
                // console.log("Coupons res", resJson)
                props.loadCoupons(resJson.Items)
            }
        })
        .catch(error => {
                console.log("Error ..", error);
                window.alert("Error fetching coupons. Check internet connection, if that is working contact dev")
              })
    }, [])

    useEffect(()=>{

    },[props.coupons])

    const coupons = props.coupons.map((item,index)=>{
        return <CouponItem item={item} index={index} key={index} mode={mode} handleEdit={handleEdit}/>
        // <tr>
        //     <td>{index}</td>
        //     <td>{item.code}</td>
        //     <td>{item.title}</td>
        //     <td>{item.discountType}</td>
        //     <td>{item.amount}</td>
        //     <td>{item.minCart}</td>
        //     <td>{item.desc}</td>
        //     <td><button>Edit</button></td>
        // </tr>
        
        
    })
    return (
        <div className="p-3">
            <div className="d-flex justify-content-center m-3">
                <button className="btn btn-success" onClick={handleAdd}>Add New Coupon</button>
            </div>
            <Modal show={show} onHide={handleClose} centered backdrop={'static'}>
                <Modal.Header closeButton>
                    <Modal.Title>New Coupon</Modal.Title>
                </Modal.Header>
                <AddCouponForm close={handleClose} data={selectedCoupon} mode={mode}/>
            </Modal>
            <Table className="mt-5" bordered hover striped variant="dark" responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Code</th>
                        <th>Title</th>
                        <th>Type</th>
                        <th>Max Discount(Rs.)</th>
                        <th>Amount</th>
                        <th>Min Cart Value</th>
                        <th>Description</th>
                        <th>Private</th>
                        <th>Limit/User</th>
                        <th>Banner ?</th>
                        <th>Banner Link</th>
                        <th>Banner Order</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {coupons}
                </tbody>
            </Table>
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        coupons : state.coupons
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        loadCoupons : (coupons)=>dispatch(loadCoupons(coupons))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Coupons)
