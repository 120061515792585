const initState ={
    authenticated : false
}

const authReducer = (auth = initState, action) => {
    switch(action.type){
        case "LOGIN_USER":
            return { authenticated : true, user : action.user}
        default:
            return auth
    }
}

export default authReducer