import {combineReducers} from 'redux';
import authReducer from './authReducer';
import couponReducer from './couponReducer';
import productsReducer from './productsReducer';

const appReducer = combineReducers({
    auth : authReducer,
    coupons : couponReducer,
    products : productsReducer
})

const rootReducer = (state,action) => {
    if (action.type === "LOGOUT_USER"){
        state = undefined
    }
    return appReducer(state,action)
}


export default rootReducer